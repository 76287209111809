#datahub-page-page-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#datahub-page-main-container {
  border-radius: 16px;
  padding: 40px;
  width: 90%;
  max-width: 800px;
  border: 1px solid #dde1e7;
  display: flex;
  flex-direction: column;
}

#datahub-page-heading {
  line-height: 1.25;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: bold;
  color: black;
}

#datahub-page-sub-heading {
  font-size: 18px;
  margin-top: 24px;
  line-height: 1.56;
  color: black;
}

.datahub-page-button-container {
  width: 80%;
  align-self: end;
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.datahub-page-button {
  border-radius: 32px;
  border-width: 0;
  padding: 10px 32px;
  font-size: 18px;
  line-height: 1.56;
  font-weight: 600;
  background-color: #005bd7;
  color: white;
}

.datahub-page-button-back-not-selected {
  border-radius: 32px;
  border: solid 1px #005bd7;
  padding: 10px 32px;
  font-size: 18px;
  line-height: 1.56;
  font-weight: 600;
  background-color: white;
  color: #005bd7;
  margin-right: 15px;
}

#datahub-page-loader-container {
  margin-top: 24px;
  height: 8px;
  background-color: #eeeff2;
  border-radius: 4;
  overflow: hidden;
}

#datahub-page-loader-bar {
  height: 8px;
  background-color: #005bd7;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 900ms;
}

#datahub-page-loader-message {
  margin-top: 8px;
  color: #4a5878;
  font-size: 16px;
  line-height: 1.5;

  &:after {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0;
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

#datahub-page-cable-img {
  margin-bottom: 40px;
}

#datahub-page-warning-img {
  margin-bottom: 24px;
}

@media (max-width: 600px) {
  #datahub-page-main-container {
    width: 100%;
    padding: 0 24px;
    border: none;
  }

  #datahub-page-cable-img {
    margin-bottom: 24px;
  }

  #datahub-page-heading {
    font-size: 24px;
    line-height: 1.33;
  }

  #datahub-page-loader-message {
    font-size: 14px;
    line-height: 1.43;
  }
}
