.SSO-page .error {
  color: rgb(255, 100, 100);
}

.SSO-page img {
  height: 16px;
  width: auto;
  margin-right: 10px;
}

.SSO-page .container {
  background: none;
  margin-top: 60px;
}

.SSO-page .primary {
  font-size: 30px;
  text-align: center;
  align-content: center;
  font-weight: bold;
  color: white;
}

.SSO-page .error {
  text-align: center;
  color: white;
}

.SSO-page .button-container {
  text-align: center;
  margin-top: 30px;
}

.SSO-page button {
  margin-left: 0;
}

.SSO-page .little-question-div,
.SSO-page .little-answer-div {
  font-size: 14px;
  margin-top: 10px;
  color: white;
}

.SSO-page .little-question-div:hover {
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

.SSO-page .little-answer-div {
  margin-top: 0;
}
