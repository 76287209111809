html,
body {
  height: 100%;
  margin: 0;
}

:root {
  --primary-max-width: 1000px;
}

/* dark mode */
:root {
  --nav-dropdown-back: rgba(77, 122, 164, 0.9);
  --main-color: white;
  --container-back: rgba(0, 0, 0, 0.5);
  --container-comp: rgba(255, 255, 255, 0.5);
  --tint-color: rgb(89, 228, 235);
  --color-grad: linear-gradient(to right, rgb(0, 12, 96), rgb(0, 116, 171));
  --color-grad-hover: linear-gradient(
    to right,
    rgb(10, 22, 106),
    rgb(10, 126, 181)
  );
  --button-border-color: transparent;
}

/* light mode */
:root {
  --nav-dropdown-back: white;
  --main-color: black;
  --container-back: rgba(255, 255, 255, 0.6);
  --container-comp: rgba(0, 0, 0, 0.5);
  --tint-color: rgb(255, 112, 45);
  --color-grad: linear-gradient(to right, rgb(253, 158, 0), rgb(250, 100, 0));
  --color-grad-hover: linear-gradient(
    to right,
    rgb(255, 168, 10),
    rgb(255, 110, 10)
  );
  --button-border-color: rgba(0, 0, 0, 0.2);
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: rgb(43,113,156); */
  height: 100%;
  min-height: calc(100vh);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.App-logo {
  display: inline-block;
}

.App-link {
  color: #080808;
}

.main-container {
  align-content: center;
  justify-content: center;
}

.navbar-dark {
  margin-bottom: 30px;
}

#navbar-container {
  padding-left: 40px;
  padding-right: 40px;
  max-width: var(--primary-max-width);
  color: white;
}

#navbar-container #basic-nav-dropdown {
  background-color: var(--nav-dropdown-back, white);
  padding: 5px;
  padding-left: 16px;
  padding-right: 16px;
  color: var(--main-color, black);
  text-decoration: none;
  border-radius: 14.5px;
  font-size: 13px;
  font-weight: 600;
}

#navbar-container #basic-nav-dropdown[aria-expanded='true'] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#navbar-container .dropdown-menu {
  background-color: var(--nav-dropdown-back, white);
  border: none;
  right: 0;
  color: var(--main-color, black);
  font-size: 13px;
  text-align: center;
  border-radius: 14.5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#navbar-container .dropdown-item {
  color: black;
}

#navbar-container .dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.logo-finance {
  font-weight: 300;
}

.sign-in-button {
  background-color: var(--nav-dropdown-bac, white);
  padding: 5px;
  padding-left: 16px;
  padding-right: 16px;
  color: var(--main-color, black);
  text-decoration: none;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 600;
}

.sign-in-button:hover {
  text-decoration: none;
}

.sign-in-button > svg {
  margin-left: 8px;
}

footer {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

footer a {
  color: white;
}

footer .container {
  max-width: var(--primary-max-width);
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 15px;
}

footer .container .col {
  text-align: center;
  font-size: 12px;
  padding-bottom: 5px;
}

footer .container .row .col:first-child:not(.fca-disclaimer) {
  font-weight: bold;
  text-align: left;
}

footer .container .row .col:nth-child(2n) {
  text-align: right;
}

.fca-disclaimer {
  font-size: 10px !important;
  margin-top: 5px;
  line-height: 1.2;
}

.zoom-hover {
  transition: transform 0.2s;
}

.zoom-hover:hover {
  transform: scale(1.1);
}
