.accounts-page .main-container {
  color: white;
}

.accounts-page .account-header {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 30px;
}

.accounts-page img {
  height: 60px;
  width: 60px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 3px;
  margin-right: 5px;
}

.loading svg {
  display: none;
}

.accounts-page button:not(.loading) .spinner-border {
  display: none !important;
}
