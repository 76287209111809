.authForm .container-fluid {
  padding: 0;
  margin-left: -15px;
  width: calc(100% + 30px);
  font-size: 15px;
}

.authForm .error {
  color: rgb(255, 100, 100);
}

.authForm .form-control {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white !important;
  font-size: 14px;
  padding: 20px 16px;
}

.authForm .link-text {
  font-size: 14px;
  color: rgb(150, 150, 150);
}

.authForm .button-col {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.authForm .button-col span {
  line-height: 40px;
}

.authForm .button-col button {
  margin: 0;
}

.authForm a {
  color: var(--light-blue);
}

.authForm a:hover {
  color: var(--light-blue-tint);
}

.authForm button {
  background-color: white !important;
  color: black !important;
}
