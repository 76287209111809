@import url('assets/fonts/graphik/style.css');
@import url('assets/fonts/rawline/style.css');

body {
  margin: 0;
  font-family: 'Graphik', 'Rawline', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --amplify-primary-color: rgb(44, 181, 204);
  --amplify-primary-tint: rgb(49, 199, 224);
  --amplify-primary-shade: rgb(49, 199, 224);
  --amplify-background-color: rgba(0, 0, 0, 0.5);
  --amplify-secondary-color: white;
  --amplify-secondary-contrast: rgba(0, 0, 0, 0.5);
  --amplify-grey: rgba(255, 255, 255, 0.8);
  --light: red !important;

  --light-blue: rgb(44, 181, 204);
  --light-blue-tint: rgb(49, 199, 224);
}

.loadingBox:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 200%;

  background: rgba(0, 0, 0, 0);
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.13) 77%,
    rgba(0, 0, 0, 0.5) 92%,
    rgba(0, 0, 0, 0) 100%
  );

  animation-duration: 1s;
  animation-name: loadingAnimation;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@keyframes loadingAnimation {
  from {
    left: -210%;
  }

  to {
    left: -30%;
  }
}
