.container.landing-page {
  max-width: var(--primary-max-width);
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
  color: white; /*var(--main-color, black);*/
  margin-bottom: 50px;
}

.landing-page h2 {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  line-height: 1.4em;
}

.landing-page p {
  font-size: 18px;
  margin: 20px 0 40px;
  line-height: 1.6;
}

.landing-page a.main-button {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin: 24px 0 24px -4px;
  background-color: white;
  padding: 16px 64px;
  color: black;
  white-space: nowrap;
  text-decoration: none;
  border-radius: 30px;
}

.landing-page a.main-button:hover {
  text-decoration: none;
  /* background-image: var(--color-grad-hover) */
}

.landing-page div {
  font-size: 14px;
  margin-top: 10px;
}

.landing-page .little-question-div:hover {
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

.landing-page .little-answer-div {
  margin-bottom: 20px;
}
