.primary-content-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.primary-content-container .main-container {
  background-color: var(--container-back);
  color: var(--main-color);
  max-width: var(--primary-max-width);
  padding: 40px;
  text-align: left;
  font-size: 14px;
  position: relative;
  overflow: hidden;
}

.primary-content-container .col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.primary-content-container .main-container h3 {
  font-size: 28px;
  padding: 0;
  font-weight: 700;
  letter-spacing: -1px;
  width: 100%;
}

.primary-content-container .children {
  padding-top: 10px;
  width: 100%;
}

.primary-content-container .main-container .children p {
  font-size: 14px;
}

.primary-content-container .main-container .checkbox-and-steps {
  justify-content: space-between;
}

.primary-content-container #main-checkbox {
  width: 18px;
  height: 18px;
}

.primary-content-container .main-container .checkbox-and-steps .checkbox-text {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: bold;
}

.primary-content-container
  .main-container
  .checkbox-and-steps
  .checkbox-text
  label {
  cursor: pointer;
}

.primary-content-container
  .main-container
  .checkbox-and-steps
  .checkbox-text
  input {
  height: 14px;
  width: 14px;
  cursor: pointer;
  margin-left: 5px;
}

.primary-content-container .progress-container {
  background-color: var(--container-back);
  height: 3px;
  max-width: var(--primary-max-width);
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.primary-content-container .progress-container .progress {
  background-color: var(--tint-color);
  height: 3px;
  position: absolute;
  left: 0;
}

.primary-content-container .progress-container .progress-background {
  background-color: var(--container-comp);
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 1px;
}

.primary-content-container .points-and-buttons {
  background-color: var(--container-back);
  color: var(--main-color);
  max-width: var(--primary-max-width);
  text-align: left;
  margin-bottom: 30px;
}

.primary-content-container .row {
  justify-content: space-between;
  flex-direction: row;
  margin: 0;
}

.primary-content-container .points-and-buttons > .row {
  padding: 32px;
}

.primary-content-container .points {
  padding: 8px;
}

.primary-content-container #primary-content-container-spinner {
  margin: 10px 20px 0 0;
}

.primary-content-container .row .backLink {
  font-size: 13px;
  font-weight: 600;
  cursor: 'pointer';
  margin-right: 20px;
  line-height: 40px;
}

.primary-content-container button,
.primary-content-container a.button {
  display: inline-block;
  margin: 0;
  font-size: 13px;
  font-weight: 800;
  background-color: white;
  height: 40px;
  padding-left: 40px;
  padding-right: 40px;
  color: black;
  text-decoration: none;
  border-radius: 50px;
  line-height: 20px;
  border: none;
}

#next-button:disabled {
  opacity: 0.2;
}
