body {
  font-family: 'Rawline Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

div.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  max-width: 90%;
  align-self: center;
  margin-left: 50%;
  position: relative;
  transform: translateX(-50%);
  padding-top: 30px;
}

p {
  font-size: calc(10px + 1.2vmin);
}
